<template>
  <el-dialog
    v-bind="{title:submitForm.pointId?'编辑兴趣点':'新建兴趣点',width:'430px'}"
    v-dialogDrag
    @open="handlerOpen"
    @closed="handlerClose"
    :visible.sync="visibleDialog">
    <el-form ref="form" label-width="105px" :model="submitForm" :rules="rules">
      <el-form-item label="兴趣点名称" prop="pointName">
        <el-input v-model="submitForm.pointName" clearable style="width: 215px" placeholder="请填写兴趣点名称"/>
      </el-form-item>

      <el-form-item label="类型" prop="pointType">
        <el-select v-model="submitForm.pointType" placeholder="请选择兴趣点类型">
          <el-option v-for="item in pointTypes" :key="item.value" :label="item.label" :value="item.value"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="图标" prop="picType">
        <el-select v-model="submitForm.picType" placeholder="请选择图标类型">
          <el-option v-for="item in picTypes" :key="item.value" :label="item.label" :value="item.value">
            <div class="flex align-center justify-between">
              {{item.label}} <img :src="item.img" class="w-24 h-24">
            </div>
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="地址" prop="roadName">
        <el-input type="textarea" :rows="2" :autosize="{minRows: 2, maxRows: 4}" v-model="submitForm.roadName" clearable style="width: 215px" placeholder="请填写地址"/>
      </el-form-item>

      <el-form-item label="工程名称" prop="projectName" v-if="submitForm.pointType===0">
        <el-input v-model="submitForm.projectName" clearable style="width: 215px" placeholder="请填写工程名称"/>
      </el-form-item>

      <el-form-item label="施工单位" prop="buildUnit" v-if="submitForm.pointType===0">
        <el-input v-model="submitForm.buildUnit" clearable style="width: 215px" placeholder="请填写施工单位"/>
      </el-form-item>

      <el-form-item label="工地联系人" prop="linkMan" v-if="submitForm.pointType===0">
        <el-input v-model="submitForm.linkMan" clearable style="width: 215px" placeholder="请填写工地联系人"/>
      </el-form-item>

      <el-form-item label="联系电话" prop="phone" v-if="submitForm.pointType===0">
        <el-input v-model="submitForm.phone" clearable style="width: 215px" placeholder="请填写联系人电话"/>
      </el-form-item>

      <el-form-item label="备注" prop="remark">
        <el-input v-model="submitForm.remark" clearable style="width: 215px" placeholder="请填写备注"/>
      </el-form-item>
    </el-form>
    <span slot="footer" class="dialog-footer">
      <el-button @click="visibleDialog=false">取 消</el-button>
      <el-button type="primary" @click="submit" :loading="loading">确 定</el-button>
    </span>
  </el-dialog>
</template>

<script>
import { addPoints } from '@/apis/monitor/interestPoint'
import { pointTypes, picTypes } from '../util'

export default {
  props: {
    visible: {
      type: Boolean,
      default: false,
      require: true
    },
    editData: {
      type: Object
    }
  },
  computed: {
    visibleDialog: {
      set (newValue) {
        this.$emit('update:visible', newValue)
      },
      get () {
        return this.visible
      }
    }
  },
  data () {
    return {
      pointTypes,
      picTypes,
      submitForm: {
        pointId: '',
        pointName: '', // 兴趣点名称
        pointType: 0, // 兴趣点类型
        picType: 0, // 图标类型
        roadName: '', // 地址
        projectName: '', // 工程名称
        buildUnit: '', // 施工单位
        linkMan: '', // 工地联系人
        phone: '', // 联系电话
        lat: '',
        lon: '',
        remark: ''
        // radius: 300,
        // remainTime: 5,
        // showType: 0
      },
      rules: {
        roleType: { required: true, trigger: 'change', message: '请选择角色类型' }
      },
      loading: false
    }
  },
  methods: {
    submit () {
      this.$refs.form.validate((valid) => {
        if (valid) {
          this.loading = true
          addPoints(this.submitForm).then((res) => {
            this.loading = false
            if (res.code == 200) {
              this.$message.success('创建成功！')
              this.$emit('refresh')
              this.visibleDialog = false
            } else {
              this.$message.error(res.msg)
            }
          })
        } else {
          return false
        }
      })
    },

    handlerClose () {
      const sourceForm = this.$options.data(this).submitForm
      this.submitForm = Object.assign({}, this.submitForm, sourceForm)
      this.$emit('update:editData', null)
      this.$refs.form.resetFields()
    },

    // 初始化表单
    handlerOpen () {
      const { submitForm, editData } = this
      if (!editData) return

      Object.keys(submitForm).forEach((key) => {
        const value = editData[key]
        submitForm[key] = value
      })
    }
  }
}
</script>
